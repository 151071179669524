// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
// @import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";

@import "~startbootstrap-sb-admin/dist/css/styles.css";

.sb-topnav{
    background-color: #0c5ad2;
}
.sb-sidenav{
    background-color: #0c5ad2;
    color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dotted-border {
  border: 2px #ff0000 dashed;
}

.signature-placeholder {
  max-width: 200px;
  border: 2px #c3c3c3 dotted;
  border-radius: 20px;
}

.bg-subitem{
  background-color: #d5d1d1 !important;
  border-bottom: 1px dashed #424242;
}